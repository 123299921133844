// extracted by mini-css-extract-plugin
export var servicesDevExpertiseCompany = "db_yw";
export var servicesDevExpertiseCompany__card = "db_yx";
export var servicesDevExpertiseCompany__emoji = "db_yz";
export var servicesDevExpertiseCompany__maxWidth140 = "db_yC";
export var servicesDevExpertiseCompany__maxWidth180 = "db_yD";
export var servicesDevExpertiseCompany__maxWidth190 = "db_yF";
export var servicesDevExpertiseCompany__maxWidth270 = "db_yG";
export var servicesDevExpertiseCompany__maxWidth310 = "db_yH";
export var servicesDevExpertiseCompany__text = "db_yB";
export var servicesDevExpertiseCompany__title = "db_yy";